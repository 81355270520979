import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import QuoteService from "../services/quote.service";


export const addquote = createAsyncThunk(
    "quote/addquote",
    async (formdata, thunkAPI) => {
      try {
          const data = await QuoteService.addQuote(formdata);
          return {productdata: data};
      } catch (error) {
        const message = 
        (error.response && 
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(); 
      }
    }
  );

  const initialState ={
    quote: [],
    message:""
}

const quoteSlice = createSlice({
    name: "quote",
    initialState,
    extraReducers: {
      [addquote.fulfilled]: (state, action) => {
        const pr = action.payload.productdata.data.data
        state.quote = [...state.quote, pr]
        state.message = action.payload.productdata.data.message
      },
      [addquote.rejected]: (state, action) => {
        state.message = action.payload.productdata.data.message
      }
    },
  });
  
  const { reducer } = quoteSlice;
  export default reducer;

