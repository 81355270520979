import React, { useState} from 'react'
import {  BrowserRouter as Router } from 'react-router-dom';
import RouteRoute from './Routes';
import Loader from '../src/components/loader/Loader.js'
import './App.css';

function App() {
  const [loader, setLoader] = useState (false);
  return (
    <>
    <Router>
    {loader && <Loader></Loader>}
    <RouteRoute setLoader={setLoader}/>
    </Router>
  </>
  );
}

export default App;
