import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const addQuote = (data) => {
  return axios.post(API_URL + "/quotes/add", data);
};

const QuoteService = {
    addQuote
  };
  
  export default QuoteService