import React, {useEffect} from "react";
import './Category.css';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Typography } from "@mui/material";
import Cups from '../../assets/images/cups.png'
import Eventreuse from '../../assets/images/ReusableCoffeeCups.png'
import Papermint from '../../assets/images/PaperPintCups.png'
import Takeaway from '../../assets/images/takeawaycups.png'
import Reusecoffee from '../../assets/images/ReusableCoffeeCups.png'
import Ceramic from '../../assets/images/ceramicmugs.png'
import Waterbottle from '../../assets/images/waterbottles.png'
import Greaseproof from '../../assets/images/greasepaperroll.png'
import Icecream from '../../assets/images/icecreamtubs.png'
import Glasswa from '../../assets/images/glassware.png'
import Rpet from '../../assets/images/rpetcups.png'
import Foodpackage from '../../assets/images/Foodpackaging.png'
import Paperbags from '../../assets/images/Paperbags.png'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getcategory } from "../../slices/category";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '140px'
}));

const ima = [
  {
    name: "Event Reusable Cups",
    path: Eventreuse,
  },
  {
    name: "Paper Pint Cups",
    path: Papermint,
  },
  {
    name: "Takeaway Coffee Cups",
    path: Takeaway
  },
  {
    name: "Reusable Coffee Cups",
    path: Reusecoffee
  },
  {
    name: "Ceramic Mugs",
    path: Ceramic
  },
  {
    name: "Water Bottles",
    path: Waterbottle
  },
  {
    name:"Greaseproof Paper",
    path: Greaseproof
  },
  {
    name:"Ice Cream",
    path: Icecream
  },
  {
    name: "Glassware",
    path: Glasswa
  },
  {
    name:'Pet Cups',
    path: Rpet
  },
  {
    name:'Food Packaging',
    path: Foodpackage
  },
  {
    name:'Paper Bags',
    path: Paperbags
  }
];

const Category = ({setLoader}) => {

  const {category }  = useSelector((state)=> state.category);

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getcategory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
      <Container maxWidth="md">
      <Box sx={{ flexGrow: 1 }}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Typography className="headLine" variant="h5" sx={{fontWeight: 'bold'}}>
          Category
        </Typography>
        </div>
        <Box sx={{my: 12}}>
        <Grid container rowSpacing={10}  columnSpacing={3}>
        {category?.map((va, index) =>{
            const img = ima.filter((v)=> v.name === va);
        return (
          <Grid item xs={12} md={6} key={index}>
            <Link to={`/detail/${va}`} state={{category: va, path: img[0]?.path}} style={{textDecoration:'none'}}>
            <Item>
              <Grid container sx={{height:'100%'}} >
                <Grid item xs={5} sx={{background:'#B6E2E8', height: '100%', position:'relative'}}>
                  <img className="cat_img" src={img.length !==0 ? img[0]?.path: Cups} style={{position: 'absolute', top:'-40px', left: '20px'}} alt="img"></img>
                </Grid>
                <Grid item xs={7} sx={{height:'100%', display: 'flex', alignItems:'center', justifyContent:'center'}}>
                    <Typography variant="h6" sx={{fontWeight: 'normal', color:'#000', px: 2}}>
                      {va}
                    </Typography>
                </Grid>
              </Grid>
            </Item>
            </Link>
          </Grid>
        )})}
        </Grid>
        </Box>
      </Box>
      </Container>
    </div>
  );
};

export default Category;
