import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function CloudUpload() {
  return (
    <Button sx={{width: '100%', height:'100px', background:'#B6E2E8'}} size='large' component="label" variant="outlined">
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <CloudUploadIcon sx={{fontSize: 50, color:'#fff'}}/>
      <p>Upload a file or drag it here</p>
      </div>
      <VisuallyHiddenInput type="file" />
    </Button>
  );
}