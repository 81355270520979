import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// Category
const getCategory = () => {
  return axios.get(API_URL + "/products/main-categories");
};

const getSubCategory = (formdata) => {
  return axios.get(API_URL + "/products/subByMainCategory", {params: formdata});
};

const getPrinting = (formdata) => {
  return axios.get(API_URL + "/products/printingStyles", {params: formdata});
};

const getQTY = (formdata) => {
  return axios.get(API_URL + "/products/minQuantity", {params: formdata});
};

const ProductService = {
  getCategory,
  getSubCategory,
  getPrinting,
  getQTY
};

export default ProductService