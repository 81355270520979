import React from 'react';
import Category from './pages/category/Category.js';
import CategoryDetail from './pages/category/Detail.js';
import Registration from './pages/category/Registration.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import ProtectedRoute from './utils/ProtectedRoute.js';


const RouteRoute = ({setLoader}) => {


  return (
    <>
    <Routes>
        <Route path='/' element={<Layout/>}>
        <Route path='/' element={<Category setLoader={setLoader}/>}></Route>
        <Route path='/detail/:detailId'>
            <Route index={true} element={<CategoryDetail setLoader={setLoader}/>}></Route>
            <Route path='registration' element={<Registration setLoader={setLoader}/>}/>
        </Route>
        <Route path='*' element={<Category setLoader={setLoader}/>}></Route>
      </Route>
      {/* <Route path='/' element={<Layout/>}>
        <Route path='/' element={<ProtectedRoute><Dashboard setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/category' element={<ProtectedRoute><Category setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/subcategory' element={<ProtectedRoute><Subcategory setLoader={setLoader}/></ProtectedRoute>}></Route>
      </Route> */}
    </Routes>
    </>
  )
}

export default RouteRoute;