import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, Button } from "@mui/material";
import "./Category.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { addquote } from "../../slices/quote";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '../../components/dialog/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import validator from "validator";

const Registration = ({ setLoader }) => {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(null)
  const { state } = useLocation();

  const { formdata } = state || {};

  const [quoted, setQuoted] = useState(formdata);
  const [validate, setValidate] = React.useState(false);

  const navigate = useNavigate();



  useEffect(()=>{
    if(formdata === undefined){
      navigate(-1);
    }
  }, [])

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    if(quoted.cust_email && quoted.cust_name !== ""){
      if(validator.isEmail(quoted.cust_email)){
        setLoader(true);
        dispatch(addquote(quoted))
          .then(() => {
            setOpen(true);
            setSuccess(true);
            setLoader(false);
          })
          .catch(() => {
            setOpen(true);
            setSuccess(false);
            setLoader(false);
          }); 
      }
      else{
        setValidate(true);
      }
    }
    else{
      setValidate(true);
    }
  };

  return (
    <div className="content">
      <Dialog open={open} setOpen={setOpen} success={success}></Dialog>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <ArrowBackIcon/> */}
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Contact
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 5 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  <span style={{ color: "#dc0000" }}>*</span> Name
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setQuoted({ ...quoted, cust_name: e.target.value })
                    }
                    required
                  >
                    <OutlinedInput
                      value={quoted?.cust_name}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {validate && quoted.cust_name === "" && <FormHelperText error>Please Enter Customer Name</FormHelperText>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  <span style={{ color: "#dc0000" }}>*</span> Email
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setQuoted({ ...quoted, cust_email: e.target.value })
                    }
                    required
                  >
                    <OutlinedInput
                      value={quoted?.cust_email}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        type: "email",
                      }}
                    />
                  </FormControl>
                  {validate && quoted.cust_email === "" ? <FormHelperText error>Please Enter Email ID</FormHelperText> : validate && !validator.isEmail(quoted.cust_email) && <FormHelperText error>Please Enter Valid Email ID</FormHelperText> }
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                   Phone Number
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setQuoted({ ...quoted, cust_mobile: e.target.value })
                    }
                    required
                  >
                    <OutlinedInput
                                        value={quoted?.cust_mobile}

                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        type: "number",
                      }}
                    />
                  </FormControl>
                  {/* {validate && quoted.cust_mobile === "" && <FormHelperText error>Please Enter Mobile Number</FormHelperText>} */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Company or Event Name
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setQuoted({
                        ...quoted,
                        company_event_name: e.target.value,
                      })
                    }
                    required
                  >
                    <OutlinedInput
                                        value={quoted?.company_event_name}

                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {/* {validate && quoted.company_event_name === "" && <FormHelperText error>Please Enter Company Name</FormHelperText>} */}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Address
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setQuoted({ ...quoted, address: e.target.value })
                    }
                    required
                  >
                    <TextField
                                        value={quoted?.address}

                      id="outlined-multiline-flexible"
                      multiline
                      rows={4}
                    />
                  </FormControl>
                  {/* {validate && quoted.address === "" && <FormHelperText error>Please Enter Address</FormHelperText>} */}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ background: "#52B4AE", my: 3, py: 2, '&:hover': {
      backgroundColor: '#B6E2E8'  } }}
                  >
                    Get A Quote
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Registration;
