import { Container, Box, Typography, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Category.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from '@mui/material/FormHelperText';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Cups from "../../assets/images/cups.png";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getprinting, getqty, getsubcategory } from "../../slices/category";
import isUrl from 'is-url';

const CategoryDetail = ({ setLoader }) => {
  const { state } = useLocation();

  const { category, path } = state || {};

  const { subCategory } = useSelector((state) => state.category);

  const { printing } = useSelector((state) => state.category);

  const val = useSelector((state) => state.category);

  const qty = val?.qty?.start_quantity;


  const navigate = useNavigate();

  const initialState = {
    product: "",
    printing_style: "",
    quantity: 0,
    delivery_date: "",
    cust_name: "",
    cust_email: "",
    cust_mobile: "",
    company_event_name: "",
    address: "",
    is_already_artwork: "yes",
    main_category: category,
    file_link: null,
  };


  const [formdata, setFormdata] = React.useState(initialState);
  const [validate, setValidate] = React.useState(false);

  const filterval = {
    main_category: category,
  };

  useEffect(()=>{
    if(qty !== undefined){
      setFormdata({...formdata, quantity: qty})
    }
  }, [qty])


  const dispatch = useDispatch();

  const handleSubcat = (e) => {
    setFormdata({ ...formdata, product: e.target.value });
    dispatch(
      getprinting({
        main_category: category,
        sub_category: e.target.value,
      })
    );
  };

  const handleQty = (e) => {
    setFormdata({ ...formdata, printing_style: e.target.value });
    dispatch(
      getqty({
        main_category: category,
        sub_category: formdata.product,
        printing_style: e.target.value
      })
    );
  };

  const handledetail = () => {
      if(formdata.product && formdata.printing_style && formdata.quantity !== ""){
        if(formdata.quantity >= qty && (formdata.file_link !== null ? isUrl(formdata.file_link): true)){
          navigate( "registration", {state: { formdata: formdata }});
        }
        else{
          setValidate(true);
        }
  }
  else{
    setValidate(true);
  }
}

  useEffect(() => {
    if (!category) {
      navigate("/");
    } else {
      setLoader(true);
      dispatch(getsubcategory(filterval))
        .unwrap()
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, []);

  return (
    <>
      <div className="detailbg"></div>
      <Box sx={{ pt: 18, pb: 8 }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img src={path || Cups} className="detail_img" alt="ima"></img>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {category}
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <span style={{ color: "#dc0000" }}>*</span> Product
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSubcat}
                      required
                      value={formdata.product}
                    >
                      {subCategory.map((va, index) => {
                        return (
                          <MenuItem key={index} value={va}>
                            {va}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {validate && formdata.product === "" && <FormHelperText error>Please Select Product</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  <span style={{ color: "#dc0000" }}>*</span> Printing Style
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleQty}
                      required
                      value={formdata.printing_style}
                    >
                      {printing.map((va, index) => {
                        return (
                          <MenuItem key={index} value={va}>
                            {va}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {validate && formdata.printing_style === "" && <FormHelperText error>Please Select Printing Style</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  <span style={{ color: "#dc0000" }}>*</span> Quantity (Min {qty})
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      color: "#fff",
                      width: "230px",
                      height: "54px",
                      lineHeight: "19px",
                      border: '1px solid #B6E2E8',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      my: 1,
                    }}
                  >
                    <Button
                      sx={{ minWidth: 0, background: "#B6E2E8", height: '100%', width:'70%',
                      '&:hover': {
      backgroundColor: '#B6E2E8'  }}}
                      onClick={() =>
                        formdata.quantity > qty
                          ? setFormdata({
                              ...formdata,
                              quantity: formdata.quantity - 1,
                            })
                          : setFormdata({
                              ...formdata,
                              quantity: formdata.quantity,
                            })
                      }
                    >
                      <RemoveIcon fontSize="small" sx={{ color: "#fff" }} />
                    </Button>
                    <FormControl
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setFormdata({ ...formdata, quantity:parseInt(e.target.value) })
                    }
                    required
                  >
                    <OutlinedInput
                      value={formdata.quantity ? formdata.quantity : ''}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        type: "number",
                      }}
                    />
                  </FormControl>
                    <Button
                      sx={{ minWidth: 0, background: "#52B4AE",  height: '100%', width:'70%',
                      '&:hover': {
      backgroundColor: '#52B4AE'  } }}
                      onClick={() =>
                        formdata.quantity >= qty
                          ? setFormdata({
                              ...formdata,
                              quantity: formdata.quantity + 1,
                            })
                          : setFormdata({
                              ...formdata,
                              quantity: formdata.quantity,
                            })
                      }
                    >
                      <AddIcon sx={{ color: "#fff" }} fontSize="small" />
                    </Button>
                  </Paper>
                  {validate && formdata.quantity < qty && <FormHelperText error>Please Select QTY morethan 50</FormHelperText>}
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  If you have it, please share the link to your artwork here
                  </Typography>
                  <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap: 15}}>
                  <Button
                  variant="contained"
                  fullWidth
                      sx={{ py: 2, my: 1, background: `${formdata.is_already_artwork === "yes" ? '#B6E2E8' : '#52B4AE'}` ,
                      '&:hover': {
      backgroundColor: '#B6E2E8'  } }}
      onClick={(e) =>
                      setFormdata({
                        ...formdata,
                        is_already_artwork: 'yes',
                      })}
                    >
Yes
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ my: 1, py: 2, background: `${formdata.is_already_artwork === "no" ? '#B6E2E8' : '#52B4AE'}`,
                      '&:hover': {
      backgroundColor: '#B6E2E8'  }  }}
      onClick={(e) =>
                      setFormdata({
                        ...formdata,
                        is_already_artwork: 'no',
                      })}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ my: 1, py: 2 , background: `${formdata.is_already_artwork === "helpme" ? '#B6E2E8' : '#52B4AE'}`,
                      '&:hover': {
      backgroundColor: '#B6E2E8'  } }}
      onClick={(e) =>
                      setFormdata({
                        ...formdata,
                        is_already_artwork: 'helpme',
                      })}
                    >
                      Help Me
                    </Button>
                  </div>
                </Grid> */}
                {formdata.is_already_artwork ==='yes' ? 
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  If you have it, please share the link to your artwork here
                  </Typography>
                  <FormControl
                    sx={{ my: 1, background: "#B6E2E8" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      
                      setFormdata({
                        ...formdata,
                        file_link: e.target.value,
                      })
                    }
                    required
                    value={formdata.file_link}
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {validate && (formdata.file_link !== null) && !isUrl(formdata.file_link) ? <FormHelperText error>Please Enter Valid Form Link</FormHelperText> : null}
                </Grid> : null}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                   Delivery Deadline
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        width: "100%",
                        background: "#B6E2E8",
                        color: "#fff",
                        my: 1,
                      }}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          delivery_date: e.format("YYYY-MM-DD"),
                        })
                      }
                      required
                    />
                  </LocalizationProvider>
                  {/* {validate && formdata.delivery_date === ""  && <FormHelperText error>Please Select Deliver Date</FormHelperText>} */}
                </Grid>
                <Grid item xs={12}>
                    <Button
                    onClick={handledetail}
                      variant="contained"
                      fullWidth
                      sx={{ background: "#52B4AE", my: 3, py: 2,
                      '&:hover': {
      backgroundColor: '#B6E2E8'  }  }}
                    >
                      Continue
                    </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Outlet />
    </>
  );
};

export default CategoryDetail;
