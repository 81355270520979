import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import ProductService from "../services/category.service";

// Category

export const getcategory = createAsyncThunk(
  "product/getcategory",
  async ( thunkAPI) => {
    try {
      const data = await ProductService.getCategory();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getsubcategory = createAsyncThunk(
  "product/getsubcategory",
  async (formdata, thunkAPI) => {
    try {
      const data = await ProductService.getSubCategory(formdata);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getprinting = createAsyncThunk(
  "product/getprinting",
  async (val, thunkAPI) => {
    try {
      const data = await ProductService.getPrinting(val);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getqty = createAsyncThunk(
  "product/getqty",
  async (val, thunkAPI) => {
    try {
      const data = await ProductService.getQTY(val);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    message:"",
    category:[],
    subCategory:[],
    printing:[],
    qty: null
}

const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: {
    [getcategory.fulfilled]: (state, action) => {
      state.category = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getcategory.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getsubcategory.fulfilled]: (state, action) => {
      state.subCategory = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getsubcategory.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getprinting.fulfilled]: (state, action) => {
      state.printing = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getprinting.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
    [getqty.fulfilled]: (state, action) => {
      state.qty = action.payload.productdata.data.data
      state.message = action.payload.productdata.data.message
    },
    [getqty.rejected]: (state, action) => {
      state.message = action.payload.productdata.data.message
    },
  },
});

const { reducer } = productSlice;
export default reducer;